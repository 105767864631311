<template>
  <section
    class="home d-flex justify-center align-center primary secondary--text"
  >
    <div class="text-center">
      <img src="@/assets/home/icon.svg" alt="" />
      <h1>Which language do your prefer?</h1>
      <section class="d-flex justify-space-around option my-16">
          <v-card class="card shadow d-flex justify-center align-center" flat>
              <v-img max-width="163" src="@/assets/img/lang/takeout.svg" />
          </v-card>
          <v-card class="card shadow d-flex justify-center align-center" flat>
              <v-img max-width="163" src="@/assets/img/lang/dine.svg" />
              <v-icon size="72" color="primary">mdi-arrow-left</v-icon>
          </v-card>
      </section>
      <section class="action">
        <v-btn
          @click.stop="selectLang('en-us')"
          height="138"
          width="348"
          elevation="0"
          class="btn mx-5"
          >English</v-btn
        >
        <v-btn
          dir="rtl"
          @click.stop="selectLang('ar-sa')"
          height="138"
          width="348"
          elevation="0"
          class="btn mx-5"
          >عربي</v-btn
        >
      </section>
    </div>
  </section>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  name: "Home",

  components: {},

  methods: {
    ...mapMutations({
      setLocale: 'app/setLocale'
    }),
    selectLang(lang  = 'en-us'){
      if(lang == 'en-us'){
        this.$vuetify.rtl = false
      }
      else{
        this.$vuetify.rtl = true
      }
      this.setLocale(lang)
      this.$router.push({name: 'menu.index'});
    }
  },

  mounted() {
    console.log(this.$vuetify);
  },
};
</script>
<style lang="scss" scoped>
.home {
  height: 100vh;
  width: 100%;
  .option{
      margin: 100px 0 !important;
      .card{
          height: 433px;
          width: 370px;
          border-radius:34px;
      }
  }
  .action {
    .btn {
      box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
        0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04) !important;
      border-radius: 74px;
      font-size: 36px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    margin-top: 120px;
  }
}
</style>
